body {
  font-family: 'Cardo';
}

h3 {
  font-family: 'Cardo';
  font-size: 4em;
  color: #fff;
  font-weight: 0 !important;
}

h4 {
  font-size: 2em;
  color: #fff;
  font-weight: 0 !important;
}

h5 {
  font-size: 1.5em;
  color: #fff;
}

#section1 {
  color: #fff;
}
.section{
  height: 100vh;
  
}

.fp-section {
  text-align: center;
}